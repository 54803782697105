<template>
  <div>
    <div
      v-if="userRole === 'admin' || userRole === 'master'"
      class="align-center text-center mt-6 mx-6"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.groupUsers") }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="users"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <br />
                        <h3>Are you sure you want to delete this user?</h3>
                        <h3>This action cannot be reverted.</h3>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn
                      outlined
                      color="#EF5350"
                      text
                      @click="confirmDeleteUser"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headersPt"
            :search="search"
            :items="users"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <br />
                        <h3>Are you sure you want to delete this user?</h3>
                        <h3>This action cannot be reverted.</h3>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      outlined
                      color="#EF5350"
                      text
                      @click="confirmDeleteUser"
                    >
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div v-if="userRole === 'user'" class="align-center text-center mt-12 mx-6">
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.groupUsers") }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="userHeaders"
            :search="search"
            :items="users"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteUser(item.id)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="userHeadersPt"
            :search="search"
            :items="users"
            :items-per-page="5"
            fixed-header
            class="elevation-1"
          >
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div
      v-if="userRole === 'admin' || userRole === 'master'"
      class="align-center text-center mt-4 mx-6"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.addNewUser") }}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex mt-4 mx-12">
          <v-flex class="md2 mt-3">
            <v-text-field
              v-model="username"
              :label="$t('global.username')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md3 mt-3 ml-12">
            <v-text-field
              v-model="email"
              :label="$t('global.email')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md2 mt-3 ml-12">
            <v-text-field
              v-model="password"
              type="password"
              :label="$t('global.password')"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>

          <v-flex class="md2 pt-0 ml-12">
            <v-container fluid>
              <v-combobox
                class=""
                v-model="role"
                :items="clientRoles"
                :search-input.sync="chosenRole"
                hide-selected
                hint="Maximum of 1 value"
                :label="$t('global.chooseRole')"
                persistent-hint
                multiple
                small-chips
                outlined
              >
              </v-combobox>
            </v-container>
          </v-flex>
          <br />
          <v-btn class="ml-16 mt-6" elevation="2" @click="createUser()">
            {{ $t("button.add") }}
          </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="error" />
        <div class="green--text" v-html="success" />
      </v-card>
    </div>
  </div>
</template>

<script>
import GroupService from "@/services/GroupService";
import UserService from "@/services/UserService";
import AppService from "@/services/AppService";
import HelperMethods from "@/utilities/HelperMethods";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userInfo: {},
      users: [],
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Date", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersPt: [
        { text: "Nome", value: "firstName" },
        { text: "Apelido", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Função", value: "role" },
        { text: "Data", value: "createdAt" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      userHeaders: [
        { text: "First Name", value: "firstName" },
        { text: "last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Date", value: "createdAt" },
      ],
      userHeadersPt: [
        { text: "Nome", value: "firstName" },
        { text: "Apelido", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Função", value: "role" },
        { text: "Data", value: "createdAt" },
      ],
      clientRoles: ["Admin", "User"],
      model: ["Vuetify"],
      username: "",
      email: "",
      password: "",
      role: "",
      groupId: "",
      chosenRole: "",
      search: null,
      error: null,
      success: null,

      //v-data-table template variables
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      userToDeleteId: null,
    };
  },
  computed: {
    ...mapState(["userRole"]),
    formTitle() {
      if (this.$i18n.locale == "pt") {
        return this.editedIndex === -1 ? "Nova Entrada" : "Editar Entrada";
      } else return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  methods: {
    async retrieveEntries() {
      try {
        const response = await GroupService.getGroupUsers(this.groupId);
        this.users = response.data;
      } catch (err) {
        console.log(err);
      }

      this.users.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.role = HelperMethods.nameRole(value["refRole"]);
      });
    },

    async createUser() {
      if (this.username.length > 16) {
        this.error = "Username must not exceed 16 characters.";
        return;
      }
      this.error = null;

      try {
        const response = await UserService.createUser({
          username: this.username,
          email: this.email,
          password: this.password,
          refRole: this.role[0],
          refGroup: this.groupId,
        });
        this.error = null;
        this.success = "User successfully created.";
        this.retrieveEntries();
        console.log("Response: ", response);

        const response1 = await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `create user ${this.username} id: ${response.data.user.id} `,
        });
        console.log("Response: ", response1);
      } catch (error) {
        this.error = error.response.data.error;
      }
    },

    async deleteUser(id) {
      UserService.delete(id)
        .then(() => {
          this.refreshList();
          const response1 = AppService.addUserAction({
            userId: this.userInfo.id,
            userRole: this.userInfo.refRole,
            message: `delete user id: ${id}`,
          });
          console.log("Response: ", response1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async refreshList() {
      this.retrieveEntries();
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.userToDeleteId = item.id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    confirmDeleteUser() {
      this.deleteUser(this.userToDeleteId);
      this.close();
    },
  },

  async mounted() {
    this.userInfo = this.$store.getters.userInfo;
    this.groupId = this.userInfo.refGroup;

    this.retrieveEntries();
  },
  watch: {
    //Combobox v-model value is being watched to prevent the selection of more than 1 value
    role(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.role.pop());
      }
    },
  },
};
</script>

<style scoped></style>
